.question {
    font-size: larger;
    margin-top: 50px;
}

.scale {
    display: flex;
    flex-direction: row;
    align-items:baseline;
    justify-content: center;
    gap: 10px
}

.value {
    margin: 0 auto;
    padding: 2px;
    border: solid #327234 1px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.line {
    width:60px;
    border-bottom: 2px solid gray;
    margin: 10px auto 20px
}

.slidecontainer {
    width: 30%; /* Width of the outside container */
}

/* The slider itself */
.slider {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: 100%; /* Full-width */
    height: 3px; /* Specified height */
    background: #d3d3d3; /* Grey background */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .2s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
}

/* Mouse-over effects */
.slider:hover {
    opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 15px; /* Set a specific slider handle width */
    height: 15px; /* Slider handle height */
    background: #327234; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
}

.slider::-moz-range-thumb {
    width: 25px; /* Set a specific slider handle width */
    height: 25px; /* Slider handle height */
    background: #4CAF50; /* Green background */
    cursor: pointer; /* Cursor on hover */
    border-radius: 50%;
}

@media screen and (max-width: 400px) {
    .slidecontainer {
        width: 80%;
    }
}
.instruction {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

.instruction-second {
    font-size: 1.5rem;
    margin-bottom: 20px;
    background-color: yellow;
    padding: 3px;
    width: 70%;
    margin: 20px auto;
    border-radius: 10px;
}

.textarea {
    width: 50%;
    height: 200px;
    margin: 20px;
    font-size: 20px;
}
.memes-choose-table {
    display: grid;
    grid-template-columns: 33% 33% 33%;
}

.submit-button {
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    margin: 10px auto;
    font-size: larger;
    color: black;
    text-decoration: none;
    cursor: pointer;
    width: 80%;
}

.instruction {
    margin: 100px auto;
    font-size: larger;
    max-width: 80%;
}

.choosedButton  {
    background-color: #671a94;
    color: white
}

@media screen and (max-width: 400px) {
    .memes-choose-table {
        grid-template-columns: 100% !important;
    }
}
.title {
    margin: 100px auto 10px;
    font-size: larger;
    font-weight: bold;
}

.instruction {
    margin: 10px auto;
    font-size: larger;
    max-width: 80%;
}

.column-div {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    margin: 0 auto;
    width: 20%;
}

.column-input {
    padding: 10px;
    margin: 10px;
    width: 100%;
}

.submit-button {
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    margin: 10px;
    font-size: larger;
    color: black;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
}

.label {
    width: 800px;
    font-size: 15px;
}

@media screen  and (max-width: 400px) {
    .column-div {
        width: 80%;
    } 

    .label {
        width: 80%;
    }

    .instruction-start {
        margin: 5px auto;
    }
}

.question-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    padding: 10px;
    margin: 10px;
    border: 1px solid gray;
    border-radius: 20px;
}

.memes-image-q {
    max-width: 90%;
    height: 100%; 
}
.memes-card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    height: 400px;
    padding: 10px;
    margin: 10px;
}

.submit-button {
    border: 1px solid black;
    background-color: white;
    padding: 10px;
    margin: 10px;
    font-size: larger;
    color: black;
    text-decoration: none;
    cursor: pointer;
    width: 100%;
}

.memes-image {
    max-width: 90%;
    max-height: 80%   
}

.choosedButton {
    background-color: darkolivegreen;
    color: white;
}
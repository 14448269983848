.memes-card-final {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    height: 30vh;
    padding: 10px;
    margin: 10px;
}

.memes-image-final {
    max-width: 90%;
    height: 100%;
}

.memes-choose-table-final {
    display: grid;
    grid-template-columns: 20% 20% 20% 20% 20%;
}

.instruction {
    margin: 100px auto;
    font-size: larger;
    max-width: 80%;
}

.instruction-box {
    width: 50%;
    margin: 0 auto;
}

.instruction2 {
    margin: 10px auto;
    font-size: larger;
}

.semi-instruction {
    margin: 10px auto 0;
    font-size: larger;
    width: 50%;
    text-align: left;
}

.submit-button {
    width: 50%
}

@media screen and (max-width: 400px) {
    .instruction-box {
        width: 100%;
    }

    .textarea {
        width: 90%;
    }


    .semi-instruction {
        width: 80%
    }

    .memes-choose-table-final {
        grid-template-columns: 100%;
    }
}
.radio-column {
    width: 20%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
}

.instruction {
    margin-bottom: 20px;
}

label {
    font-size: 18px;
}

@media screen and (max-width: 400px) {
    .radio-column {
        width: 80%;
    }
}